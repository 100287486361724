import "./ncx-header.scss";
import ComponentBase from "./ncx-base";
import { html } from "lit";

class NcxHeader extends ComponentBase {
    static get tagName() { return 'ncx-header'; }

    render() {
        return html`
            inCassa
        `;
    }
}
NcxHeader.Register();
export default NcxHeader;