import './ncx-base.scss';
import { LitElement, html } from 'lit';

class ComponentBase extends LitElement {
    static get tagName() { return 'ncx-base'; }

    render() {
        return html`
            __TO_BE_OVERWRITTEN__
        `
    }

    createRenderRoot() {
        return this;
    }

    static Register() {
        customElements.define(this.tagName, this);
    }
}

export default ComponentBase;