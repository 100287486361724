import './ncx-section-contacts.scss';
import ComponentBase from './ncx-base';
import { html } from 'lit';
import axios from 'axios';

class NcxSectionContacts extends ComponentBase {
    static get tagName() { return 'ncx-section-contacts'; }

    constructor() {
        super();
    }

    render() {
        return html`
            <div class="text">
                <div class="form center-align">
                    <h1>Contatti</h1>
                    <div class="contacts-list">
                        <div><i class="material-icons">email</i> <a href="mailto:info@incassa.app">info@incassa.app</a></div>
                        <div><i class="material-icons">local_phone</i> <a href="tel:+393480197683">3480197683</a>&nbsp;(WhatsApp)</div>
                    </div>
                    <p>Compila il modulo qui sotto per essere ricontattato</p>
                    <form validate>
                        <div class="row">
                            <div class="input-field">
                                <input id="email" name="email" type="email" class="validate" required>
                                <label for="email">Email</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field">
                                <input id="name" name="name" type="text">
                                <label for="name">Nome (opzionale)</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field">
                                <input id="event_name" name="event_name" type="text">
                                <label for="event_name">Nome evento (opzionale)</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field">
                                <input id="tel" name="tel" type="tel">
                                <label for="tel">Telefono (opzionale)</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field">
                                <textarea id="msg" class="materialize-textarea"></textarea>
                                <label for="msg">Messaggio (opzionale)</label>
                            </div>
                        </div>
                        <div class="success-message">Grazie! Ti ricontatteremo il prima possibile.</div>
                        <div class="error-message">Si è verificato un errore. Riprova.</div>
                        <button class="btn waves-effect waves-light" type="submit" name="action">
                            Invia
                            <i class="material-icons right">send</i>
                        </button>
                    </form>
                </div>
            </div>
        `
    }

    firstUpdated() {
        const form = this.querySelector('form');
        const btn = this.querySelector('button');
        const emailField = form.querySelector('input#email');
        const nameField = form.querySelector('input#name');
        const eventNameField = form.querySelector('input#event_name');
        const telField = form.querySelector('input#tel');
        const msgField = form.querySelector('textarea#msg');
        form.onsubmit = async evt => {
            evt.preventDefault();
            const data = {
                Name: nameField.value,
                Email: emailField.value,
                EventName: eventNameField.value,
                Tel: telField.value,
                Msg: msgField.value
            };
            try {
                btn.setAttribute('disabled', true);
                await axios.post('https://incassa-contact-form.fabio-blasizza.workers.dev/', data)
                emailField.value = '';
                emailField.classList.remove('valid');
                emailField.dispatchEvent(new Event('blur'));
                emailField.classList.remove('invalid');
                nameField.value = '';
                nameField.dispatchEvent(new Event('blur'));
                eventNameField.value = '';
                eventNameField.dispatchEvent(new Event('blur'));
                telField.value = '';
                telField.dispatchEvent(new Event('blur'));
                msgField.value = '';
                msgField.dispatchEvent(new Event('blur'));
                form.querySelector('.success-message').classList.add('visible');
                setTimeout(() => {
                    form.querySelector('.success-message').classList.remove('visible');
                }, 3000)
            } catch (error) {
                form.querySelector('.error-message').classList.add('visible');
                setTimeout(() => {
                    form.querySelector('.error-message').classList.remove('visible');
                }, 3000)
                console.error(error);
            } finally {
                btn.removeAttribute('disabled');
            }
        }
    }
}
NcxSectionContacts.Register();
export default NcxSectionContacts;