import './ncx-section-discover.scss';
import ComponentBase from './ncx-base';
import { html } from 'lit';

class NcxSectionDiscover extends ComponentBase {
    static get tagName() { return 'ncx-section-discover'; }

    constructor() {
        super();
    }

    render() {
        return html`
            <div>
                <h2>Di cosa si tratta</h2>
                <p>Benvenuti nell'applicativo per la gestione delle sagre!</p>
            </div>
            <div>
                <h4>Semplice ed intuitivo</h2>
                <p>Il nostro software offre una soluzione completa per facilitare l'ordinazione e la gestione degli ordini durante le sagre e gli eventi gastronomici.</p>
            </div>
            <div>
                <h4>Interfaccia user-friendly</h2>
                <p>Su tablet per la cassa e monitor TV per il monitoraggio degli ordini, semplifichiamo il processo di gestione, consentendo agli organizzatori di concentrarsi sulla soddisfazione dei clienti.</p>
            </div>
            <div>
                <p>Ottimizza il tuo evento con la nostra soluzione all'avanguardia per una gestione efficiente e senza problemi!</p>
            </div>
            <div>
                <h4>Come fare</h2>
                <p>Ci occupiamo dell'intera messa in opera del sistema, garantendo un'installazione rapida e senza intoppi.</p>
                <p>Forniamo tutte le attrezzature necessarie, come monitor TV, tablet e stampanti, qualora ne foste sprovvisti.</p>
                <p>Il nostro servizio è completo ed assicura che tu abbia tutto ciò di cui hai bisogno per gestire l'evento con successo, senza preoccupazioni tecniche.</p>
            </div>
        `
    }

    firstUpdated() {
        this.carousel = this.querySelector('.carousel')
        this.instance = M.Carousel.init(this.carousel, {
            fullWidth: true,
            indicators: true
        });
    }
}
NcxSectionDiscover.Register();
export default NcxSectionDiscover;