import './ncx-section-hero.scss';
import ComponentBase from './ncx-base';
import { html } from 'lit';

class NcxSectionHero extends ComponentBase {
    static get tagName() { return 'ncx-section-hero'; }

    constructor() {
        super();
    }

    render() {
        return html`
            <div id="particles-js"></div>
            <div class="hero-text">
                <h1>inCassa</h1>
                <p>L'app che ti semplifica la sagra</p>
            </div>
        `
    }

    firstUpdated() {
        particlesJS.load('particles-js', '/particles.json', () => {
            console.log('callback - particles.js config loaded');
        });
    }
}
NcxSectionHero.Register();
export default NcxSectionHero;