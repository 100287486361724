import './app.scss';
import { html } from "lit";
import ComponentBase from "./components/ncx-base";
import './components/ncx-section-hero';
import './components/ncx-section-discover';
import './components/ncx-section-contacts';
import './components/ncx-header';
import './components/ncx-footer';

class App extends ComponentBase {
    static get tagName() { return 'ncx-app'; }

    render() {
        return html`
            <!--ncx-header></ncx-header-->
            <ncx-section-hero></ncx-section-hero>
            <ncx-section-discover></ncx-section-discover>
            <ncx-section-contacts></ncx-section-contacts>
            <!--ncx-footer></ncx-footer-->
        `
    }
}
App.Register();
export default App;