import "./ncx-footer.scss";
import ComponentBase from "./ncx-base";
import { html } from "lit";

class NcxFooter extends ComponentBase {
    static get tagName() { return 'ncx-footer'; }

    render() {
        return html`
            &#169; ${(new Date()).getFullYear()} inCassa.
        `
    }
}
NcxFooter.Register();
export default NcxFooter;